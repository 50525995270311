//import axios from 'axios';
import http from '../../axios/axios_conf';
import api from '../../routers/routers';

class communications {
 
  list(communications_data){
    return http.get( api.communications.get.endpoint, communications_data);
  }

  create(communications_data){
    return http.post( api.communications.create.endpoint, communications_data );
  }
  
  update(file,params){
    return http.put( api.communications.update.endpoint+params, file );
  }
  
  delete(communications_data){
    return http.delete( api.communications.delete.endpoint+communications_data );
  }

}

export default new communications();